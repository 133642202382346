import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Global, css } from '@emotion/react';

import background from '../images/faruk/img-background.png';
import man from '../images/faruk/img-man.png';
import farukLogo from '../images/faruk/img-logo-farukk.png';
import phone from '../images/faruk/img-phone.png';
import azarLogo from '../images/faruk/img-logo-azar.png';
import appStore from '../images/faruk/btn-appstore.png';
import playStore from '../images/faruk/btn-playstore.png';
import description from '../images/faruk/img-description.png';

const style = {
  global: css`
    body {
      background-color: #000;

      margin: auto;
      overflow-x: hidden;
      min-height: 100%;

      background-color: #1f1f20;

      @media (min-width: 769px) {
        max-width: 100%;
      }
    }

    main {
      display: flex;
      align-items: center;

      width: 100%;
      height: 100vh;
    }
  `,
  poster: css`
    position: relative;

    width: 100%;
    height: calc(100vw * 4 / 3);

    @media (min-width: 769px) {
      max-height: 100%;
    }
  `,
  background: css`
    position: absolute;
    padding: 0;

    width: 100%;
    height: 100%;

    z-index: -1;
  `,
  man: css`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & img {
      position: absolute;
      height: 100%;
      left: 0;
    }
  `,
  bottom: css`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40%;
    overflow: hidden;

    display: flex;
    padding: 0 30px;

    box-sizing: border-box;

    & div {
      position: relative;
      overflow: hidden;
    }

    & div img {
      position: absolute;
    }

    @media (min-width: 769px) {
      height: 50%;
      width: 74%;
      right: 0;
    }
  `,
  phoneWrapper: css`
    flex: auto;
    @media (min-width: 769px) {
      order: 2;
    }
  `,
  phone: css`
    height: 98%;
    bottom: 0;

    @media (min-width: 769px) {
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  description: css`
    & img {
      width: 100%;
      top: 45%;
      transform: translateY(-50%);
    }

    flex: auto;

    @media (min-width: 769px) {
      flex: 1.3;
    }
  `,
  logoWithButtons: css`
    position: absolute;
    top: 40%;
    right: 0;
    width: 50%;
    height: 40%;
    margin-right: 20px;

    display: flex;

    @media (min-width: 769px) {
      top: 0;
      margin-top: 55px;
    }
  `,
  buttons: css`
    display: flex;

    a:first-of-type {
      margin-right: 8px;
    }
    img {
      width: 100%;
    }

    @media (min-width: 769px) {
      a:first-of-type {
        margin-right: 16px;
      }
    }
  `,
  logo: css`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 50%;

    box-sizing: border-box;
    padding-bottom: 10px;

    margin-bottom: 10px;

    & img {
      position: absolute;
      width: 100%;
    }

    @media (min-width: 769px) {
      height: 80%;
      margin-bottom: 0;

      & img {
        height: 100%;
        right: 0;
      }
    }
  `,
  faruk: css`
    position: absolute;
    right: 0;
    margin: 20px;

    width: 30%;

    & img {
      position: absolute;
      width: 100%;
    }

    @media (min-width: 769px) {
      bottom: 0;
      left: 0;
      margin: 40px;
      max-width: 279px;

      & img {
        bottom: 0;
      }
    }
  `,
};

const farukPage: FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Azar x Faruk K</title>
      </Helmet>
      <Global styles={style.global} />
      <main>
        <div css={style.poster}>
          <img src={background} css={style.background} alt='background' />
          <div css={style.man}>
            <img src={man} alt='man using Azar' />
          </div>
          <div css={style.bottom}>
            <div css={style.phoneWrapper}>
              <img src={phone} css={style.phone} alt='Azar screenshot' />
            </div>
            <div css={style.description}>
              <a href='https://bit.ly/3oxj5ct' target='_blank' rel='noreferrer'>
                <img src={description} alt='description' />
              </a>
            </div>
          </div>
          <div css={style.logoWithButtons}>
            <div>
              <div css={style.logo}>
                <img src={azarLogo} alt='Azar logo' />
              </div>
              <div css={style.buttons}>
                <a
                  href='https://bit.ly/3oxj5ct'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={appStore} alt='App store' />
                </a>
                <a
                  href='https://bit.ly/3oxj5ct'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={playStore} alt='Google play store' />
                </a>
              </div>
            </div>
          </div>
          <div css={style.faruk}>
            <img src={farukLogo} alt='faruk.k x Azar' />
          </div>
        </div>
      </main>
    </>
  );
};

export default farukPage;
